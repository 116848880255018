/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/prismjs@1.29.0/components/prism-java.min.js
 * - /npm/prismjs@1.29.0/components/prism-bash.min.js
 * - /npm/prismjs@1.29.0/components/prism-nginx.min.js
 * - /npm/prismjs@1.29.0/components/prism-c.min.js
 * - /npm/prismjs@1.29.0/components/prism-python.min.js
 * - /npm/prismjs@1.29.0/components/prism-less.min.js
 * - /npm/prismjs@1.29.0/components/prism-sass.min.js
 * - /npm/prismjs@1.29.0/components/prism-powershell.min.js
 * - /npm/prismjs@1.29.0/components/prism-ini.min.js
 * - /npm/prismjs@1.29.0/components/prism-cmake.min.js
 * - /npm/prismjs@1.29.0/components/prism-cpp.min.js
 * - /npm/prismjs@1.29.0/components/prism-json.min.js
 * - /npm/prismjs@1.29.0/components/prism-yaml.min.js
 * - /npm/prismjs@1.29.0/components/prism-nasm.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
